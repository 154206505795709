// cSpell:disable
export const messages = {
  pt_BR: {
    translation: {
      module: {
        air: 'Air',
        dialysis: 'Dialysis',
        infusion: 'Infusion',
        monitor: 'Monitor',
      },
      menu: {
        alarms: 'Alarmes',
        configERP: 'Frequência PEP',
        dashboard: 'Dashboard',
        history: 'Histórico',
        sendERP: 'Envio PEP',
        deployment: 'Implantação de hospitais',
        ecg: 'ECG',
      },
      configMenu: {
        userManagement: 'Gestão de usuários',
        assistentialManagement: 'Gestão assistencial',
        generalSettings: 'Configurações gerais',
      },
      title: {
        addUser: 'Orchestra | Cadastro de Usuário',
        air: 'Orchestra | Ventilador Pulmonar',
        alarms: 'Orchestra | Níveis de Alarmes',
        alarmsConfig: 'Orchestra | Configuração de alarme',
        changePassword: 'Orchestra | Troca de senha',
        dashboard: 'Orchestra | Dashboard',
        erpConfig: 'Orchestra | Parâmetros de envio para ERP',
        erpSend: 'Orchestra | Validação e envio para ERP',
        history: 'Orchestra | Histórico do Monitor',
        home: 'Orchestra | Leitos',
        infusion: 'Orchestra | Bomba de infusão',
        login: 'Orchestra | Login',
        permissionDenied: 'Orchestra | Permissão negada!',
        resetPassword: 'Orchestra | Reset de senha',
        users: 'Orchestra | Usuários',
        usersLogs: 'Orchestra | Logs de usuários',
      },
      ssvv: {
        arterialPressure: 'Pressão Arterial',
        invasivePressure: 'Pressão invasiva',
        diastolicArterialPressure: 'Pressão Arterial Diastólica',
        heartRate: 'Freq. Cardiaca',
        meanArterialPressure: 'Pressão Arterial Média',
        oxygenSaturation: 'Sat. de Oxigenio',
        respiratoryRate: 'Freq. Respiratória',
        systolicArterialPressure: 'Pressão Arterial Sistólica',
        temperature: 'Temperatura',
        carbonDioxide: 'Dióxido de carbono',
        initials: {
          arterialPressure: 'PNI',
          diastolicArterialPressure: 'PAD',
          heartRate: 'FC',
          meanArterialPressure: 'PAM',
          oxygenSaturation: 'SpO₂',
          respiratoryRate: 'FR',
          systolicArterialPressure: 'PAS',
          temperature: 'Temp',
          carbonDioxide: 'CO₂',
          invasivePressure: 'PAI',
        },
        units: {
          arterialPressure: 'mmHg',
          invasivePressure: 'mmHg',
          diastolicArterialPressure: 'mmHg',
          heartRate: 'bpm',
          meanArterialPressure: 'mmHg',
          oxygenSaturation: '%',
          respiratoryRate: 'rpm',
          systolicArterialPressure: 'mmHg',
          temperature: '°C',
          carbonDioxide: 'mmHg',
        },
        noData: 'NÃO HÁ DADOS DE MONITOR',
      },
      infusion: {
        volumeBasalInfused: 'Volume basal infundido',
        volumeTotalInfused: 'Volume total de líquido infundido',
        chooseMedication: 'Substância não identificada',
        noSubstancesFound: 'NENHUMA SUBSTÂNCIA ENCONTRADA',
        contactTheAdministrator: 'CONTATE O ADMINISTRADOR',
        medication: 'Medicamento',
        dosage: 'Dosagem',
        wait: 'Aguarde...',
        noData: 'NÃO HÁ DADOS DE INFUSION',
      },
      air: {
        noData: 'NÃO HÁ DADOS DE AIR',
        configIndicators: 'configurações de indicadores',
        mode: 'modalidade',
        opMode: 'modo de operação',
        incidence: 'incidência',
        complacency: 'Complacência EST',
        resistance: 'resistência',
        VENT_MODE: 'MODALIDADE',
        NIV: 'Ventilação não invasiva',
        BIVENT_APRV: 'Ventilação de liberação de pressão das vias aéreas',
        NAVA: 'Assistência ventilatória neuralmente ajustada',
        NAVA_NIV: 'Assistência ventilatória neuralmente ajustada / Ventilação não Invasiva',
        NASAL_CPAP: 'Pressão positiva contínua nas vias aéreas / Nasal',
        CPAP: 'Pressão positiva contínua nas vias aéreas',
        PS_CPAP: 'Pressão positiva contínua nas vias aéreas / Pressão Assistida',
        DUAL_PAP: 'Ventilação em dois níveis de pressão positiva',
        SIMV_PS: 'Ventilação obrigatória intermitente sincronizada com pressão controlada',
        SIMV_PRVC_VS: 'Ventilação obrigatória intermitente sincronizada com volume controlado',
        VCV: 'Ventilação obrigatória com volume controlado',
        SIMV_PRVC_PS: 'Ventilação obrigatória intermitente sincronizada / controle de volume regulado por pressão + suporte de pressão',
        VS: 'Volume assistido',
        PRVC: 'Controle de volume regulado por pressão',
        VS_PRVS: 'Controle de volume regulado por pressão / Volume Assistido',
        VC: 'Volume controlado',
        VC_VS: 'Volume controlado / Volume assistido',
        PS_PC: 'Pressão controlada / Pressão assistida',
        PC: 'Pressão controlada',
        NIV_PS: 'Ventilação Não Invasiva / Pressão assistida',
        HIGH_FLOW: 'Cânula Nasal de Alto Fluxo',
        MMV: 'Ventilação espontânea com volume minuto mandatório',
        PLV: 'Ventilação por pressão limitada e fluxo contínuo (neonatal)',
        FLOW_TRIGGER: 'MODO DE OPERAÇÃO',
        CONTROLLED: 'Controlada',
        ASSISTED: 'Assistida',
        COMBINED: 'Combinada',
        FLOW_CONTROL: 'INCIDÊNCIA',
        PRESSURE: 'Pressão',
        VOLUME: 'Volume',
        FLOW: 'Fluxo',
        CSTAT: 'Cest',
        PMEAN: 'PMedia',
        PEEP: 'PEEP',
        RRATE: 'FR',
        PLAT: 'Pausa Insp',
        VMI: 'VM Insp',
        VME: 'VM Exp',
        PPEAK: 'Ppico',
        PPLAT: 'Pplatô',
        VTE: 'VC Exp',
        VTI: 'VC Insp',
        SENS: 'SENS',
        PINS: 'Pinsp',
        CDYN: 'Cdin',
        RI: 'RVA Insp',
        RE: 'RVA Exp',
        PRSENS: 'SENS',
        FLSENS: 'SENS',
        SPO2: 'FiO₂',
        RATIO_IE: 'I:E',
        units: {
          CSTAT: 'ml/cmH₂O',
          PMEAN: 'cmH₂O',
          PEEP: 'cmH₂O',
          RRATE: 'rpm',
          PLAT: 's',
          VMI: 'l/min',
          VME: 'l/min',
          PPEAK: 'cmH₂O',
          PPLAT: 'cmH₂O',
          VTE: 'ml/kg',
          VTI: 'ml/kg',
          PINS: 'cmH₂O',
          CDYN: 'ml/cmH₂O',
          RI: 'cmH₂O/l/s',
          RE: 'cmH₂O/l/s',
          PRSENS: 'cmH₂O',
          FLSENS: 'l/min',
          SPO2: '%',
          RATIO_IE: '',
        },
      },
      abbreviation: {
        diastolicArterialPressure: 'PAD',
        diastolicArterialPressure_measureUnit: 'PAD (mmHg)',
        heartRate: 'FC',
        heartRate_measureUnit: 'FC (bpm)',
        meanArterialPressure: 'PAM',
        meanArterialPressure_measureUnit: 'PAM (mmHg)',
        oxygenSaturation: 'SAT',
        oxygenSaturation_measureUnit: 'SAT (%)',
        respiratoryRate: 'FR',
        respiratoryRate_measureUnit: 'FR (rpm)',
        systolicArterialPressure: 'PAS',
        systolicArterialPressure_measureUnit: 'PAS (mmHg)',
        temperature: 'TEMP',
        temperature_measureUnit: 'TEMP (ºC)',
        carbonDioxide: 'CO2 (mmHg)',
      },
      pageHome: {
        modal: {
          title: 'Atenção',
          one: 'O preset "{{presetName}}" não está associado e será excluído em breve.',
          many: 'Os seguintes presets não estão associados e serão excluídos em breve.',
        },
        reorderError: 'Ocorreu um erro ao reordenar os leitos, tente novamente.',
      },
      historyMonitorSelect: {
        detailedSight: 'visão detalhada (1min)',
        mediumSight: 'visão intermediária (15min)',
        wideSight: 'visão ampla (30min)',
      },
      monitorHistory: {
        noData: 'NÃO HÁ DADOS EM HISTÓRICO',
      },
      sendERP: {
        sendValidData: 'Enviar dados validados',
        sendNow: 'Coletar agora',
        selectAll: 'Selecionar todos',
        select: 'Selecionar',
        date: 'Data',
        hour: 'Hora',
        noData: 'SEM DADOS PEP',
        sentData: 'Dados enviados com sucesso!',
        updatedData: 'Dados atualizados com sucesso!',
        errorSentData: 'Dados não enviados, tente novamente.',
        totalItems: 'Total {{count}} registros',
        modalTitle: 'Atenção!',
        modalMsg: 'Dados para o prontuário sem envio há mais de um dia. Por favor verificar.',
        disabled: 'DESATIVADO',
      },
      frequencyERP: {
        hospital: 'Hospital',
        ward: 'Ala',
        timer: 'Tempo da coleta',
        autoSend: 'Envio automático para o MV',
        toastSuccess: 'Valores alterados com sucesso!',
        toastFail: 'Ocorreu um erro, tente novamente.',
      },
      alarmsConfig: {
        availHospitals: 'Hospitais disponiveis [ala]',
        selectedHospitals: 'Hospitais selecionados [ala]',
        resultCount: 'Exibindo {{count}} de {{total}} resultados',
        presetName: 'Nome do preset',
        selectedPreset: 'Preset selecionado: ',
        expertise: 'Especialidade',
        whoCreated: 'Criado por {{name}}',
        titleCreator: 'Criado por',
        createdAt: 'Data/Hora da criação',
        actions: 'Ações',
        copyof: 'Cópia{{count}} de - ',
        modal: {
          titleDuplicated: 'Duplicar',
          textDuplicated: 'Este preset foi duplicado. Deseja editar agora?',
          titleFail: 'Erro',
          textFailDuplicated: 'Ocorreu um erro, tente novamente',
          titleRemove: 'Excluir',
          textRemove: 'Deseja excluir o preset "{{name}}"?',
          textFailRemove: 'Ocorreu um erro ao excluir preset, tente novamente',
        },
      },
      alarmsControl: {
        link: 'Vincular hospital',
        duplicate: 'Duplicar',
        edit: 'Editar',
        erase: 'Apagar',
        publish: 'Publicar',
        unpublish: 'Despublicar',
        addAll: 'Adicionar todos >>',
        addSelected: 'Adicionar selecionado >',
        removeSelected: '< Remover selecionado',
        removeAll: '<< Remover todos',
        noData: 'NÃO HÁ DADOS EM ALARMES',
      },
      presetConfig: {
        pageTitle: 'Preset de alarmes',
        presetName: 'Nome do preset',
        specialty: 'Especialidade',
        createdBy: 'Criado por {{name}}',
        identification: 'Identificação',
        seeMore: 'Ver mais',
        seeLess: 'Ver menos',
        vitalSign: 'Sinais Vitais',
        success: {
          title: 'SUCESSO',
          text: 'Um novo preset foi criado',
        },
        fail: {
          title: 'ERRO',
          text: 'Ocorreu um erro, tente novamente.',
        },
      },
      presetEdit: {
        success: {
          title: 'SUCESSO',
          text: 'As modificações foram salvas',
        },
        fail: {
          title: 'ERRO',
          text: 'Ocorreu um erro ao atualizar o preset, tente novamente',
        },
      },
      patientRecord: {
        pageTitle: 'Registro do Paciente',
        dataSentToPep: 'Informações do paciente',
        byEquipment: 'Equipamento',
        indicator: 'Indicador',
        condition: 'Condição',
        value: 'Valor',
        byDate: 'Data',
        addFilter: 'Adicionar filtro',
        moreThen: 'Maior que',
        lessThen: 'Menor que',
        equal: 'Igual',
        selectEquipment: 'Selecione um equipamento',
        search: {
          title: 'Atenção!',
          text: 'Nenhuma informação encontrada para esta busca.',
        },
      },
      multiRange: {
        low: 'Baixo',
        high: 'Alto',
        tooLow: 'Muito baixo',
        tooHigh: 'Muito alto',
        normal: 'Normal',
      },
      specialties: {
        neonatal: 'Neonatal',
        pediatric: 'Pediátrica',
        mixedPediatric: 'Pediátrica mista',
        pediatricNeonatal: 'Pediátrica e neonatal',
        adult: 'Adulto',
        cardiac: 'Cardiopata',
        coronary: 'Coronariana',
        surgical: 'Cirúrgica',
        neurological: 'Neurológica',
      },
      assistentialManagement: {
        titleFrequency: 'Defina a frequência de envio da amostragem dos dados para o prontuário',
        rulerTitle: 'Frequência do envio',
      },
      levels: {
        fail: 'Sem dados',
        high: 'alto',
        low: 'baixo',
        normal: 'normal',
        veryHigh: 'muito alto',
        veryLow: 'muito baixo',
      },
      text: {
        accessDenied: 'Você não tem permissão o suficiente para acessar essa página.',
        commercialMessage: 'Se você deseja se tornar uma Tele-UTI completa desbloqueando nossos módulos adicionais para monitoramento remoto de bombas (Orchestra Infusion) e ventiladores mecânicos (Orchestra Air), <0>clique aqui</0> ou fale com o nosso time de "Sucesso do Cliente"',
        criteriaForCreatingPassword: 'Critérios para criação de senha:',
        criteriaForCreatingPasswordDifferentPassword: 'Senha diferente da nova senha, verifique.',
        criteriaForCreatingPasswordNumberOfChars: 'A senha deve conter, entre {{minCharacteres}} e {{maxCharacteres}} caracteres.',
        criteriaForCreatingPasswordTypesOfChars: 'A senha deve conter letras maiúsculas, minúsculas e números.',
        doNotHaveEmail: 'Não possui e-mail',
        doYouWantRemoveUser: 'Deseja realmente excluir?',
        expiredSession: 'Sua sessão foi encerrada por inatividade no sistema. É preciso efetuar login novamente',
        firstAccessText: 'Para continuar o uso do sistema, por segurança precisamos que você crie uma nova senha.',
        forgotPassword: 'Esqueci minha senha',
        goToInitialPage: 'Ir para a página inicial',
        informID: 'Por favor. Informe seu ID de profissional',
        informPassword: 'Por favor. Informe sua senha',
        loginLabel: 'Entre com seus dados para login',
        logoutConfirmation: 'Tem certeza que deseja sair do Orchestra?',
        noChangesToSave: 'Não há alterações para serem salvas',
        noDataFound: 'Nenhum dado encontrado',
        noLogsRegistred: 'Nenhum log registrado no momento',
        noRegistreFoundErp: 'Nenhum registo encontrado para validação e envio no ERP.',
        noUserRegistred: 'Nenhum usuário cadastrado',
        preset: 'Defina a frequência de envio da amostragem dos dados para o prontuário, entre 15, 30 e 60 minutos (arraste até a opção desejada).',
        searchResults: 'Exibindo {{amount}} de {{total}} resultados',
        setRange: 'Defina os valores de range para o indicador:',
        typeAName: 'Por favor, digite um nome',
        typeAnEmail: 'E-mail para onde será enviado a senha',
        typeARole: 'Selecione o nível de permissão',
        typeCodeErp: 'Código PEP',
        typePermissionLevel: 'Selecione o nível de permissão',
        typePhoneNumber: 'DDD + Telefone (opcional)',
        typeProfessionalID: 'Por favor, digite a ID de profissional',
        typeProfessionalIDToRecoverPassword: 'Informe seu ID Profissional para receber o código de acesso.',
        typeValidProfessionalID: 'Por favor, digite uma ID de profissional válida',
        typeXdigits: 'Por favor, sua senha deve conter no minimo {{count}} digitos',
        typeYourPassword: 'Digite sua senha',
        typeYourPasswordConfirmation: 'Digite sua confirmação de senha',
        typeYourProfessionalIDAndCode: 'Informe seu ID Profissional e código recebido por e-mail.',
        uniquepepIdForAllHospitals: 'Código PEP único para todos os hospitais',
        userLogsTitle: 'Administrador, acompanhe abaixo os LOGs do sistema referentes ao uso de todos os usuários cadastrados.',
      },
      conectionStatus: {
        conected: 'Conectividade ON',
        desconected: 'Conectividade OFF',
      },
      button: {
        back: 'Voltar',
        cancel: 'Cancelar',
        logOut: 'Sair',
        save: 'Salvar',
        sendValidatedData: 'Enviar dados validados',
        yes: 'Sim',
        no: 'Não',
        uploadAgain: 'Subir novamente',
        close: 'Fechar',
        confirm: 'Confirmar',
        everyOne: 'Todos',
        search: 'Buscar',
        clear: 'Limpar',
        exclude: 'Excluir',
        enableIt: 'Ativar',
        disableIt: 'Inativar',
        create: 'Criar',
        freezeMoment: 'Congelar momento',
        captureMoment: 'Capturar momento',
      },
      placeholder: {
        typeHere: 'Digite aqui',
      },
      bedStatus: {
        DISABLED: 'Desativado',
        errorPID: 'Favor verificar datos del PID',
        FREE: 'Livre',
        ON_HOLD: 'Em espera',
        ACTIVE_UNPLUGGED: 'Desconectado',
        ON_HOLD_UNPLUGGED: 'Desconectado',
        FREE_UNPLUGGED: 'Desconectado',
        WAITING_ADMISSION: 'Anônimo', // Used in home beds
        anonyPid: 'Aguardando admissão',
      },
      alarmConfig: {
        cantBeEmpty: 'Este campo não pode ser vazio',
        tooLowNotLowerThan: 'O valor muito baixo não pode ser menor que',
        tooLowNotbiggerThan: 'O valor muito baixo não pode ser maior que o baixo',
        lowNotlowerThanTooLow: 'O valor baixo não pode ser menor ou igual a muito baixo',
        hightNotlowerThanLow: 'O valor alto não pode ser menor ou igual a baixo',
        tooHightNotlowerThanHight: 'O valor muito alto não pode ser menor ou igual a alto',
        tooHightNotHigherThan: 'O valor muito alto não pode ser maior que',
      },
      confirmation: {
        keep: 'Continuar na página',
        leave: 'Sair sem salvar',
        changesNotSaved: 'As modificações não foram salvas',
        inactivate: 'Deseja inativar?',
      },
      feedback: {
        allowedFileExtensions: 'Extensão do arquivo permitida .Xlsx.',
        configSaved: 'Configurações salvas com sucesso',
        errorDisassociatingPatient: 'Error ao desassociar o paciente.',
        errorSavingConfig: 'Erro ao salvar as configurações',
        errorSavingPatient: 'Error ao salvar o paciente.',
        errorUnknown: 'Erro inesperado, tente novamente',
        errorWhenChangingPresets: 'Erro ao alterar os presets',
        errorWhenCreatingUser: 'Erro ao criar usuário',
        errorWhenLoadingConfig: 'Falha ao carregar as configurações',
        errorWhenLoadingPresets: 'Falha ao carregar os presets',
        errorWhenLoadingSickbedConfig: 'Falha ao carregar informações do leito',
        errorWhenLoadingUserList: 'Falha ao carregar lista de usuários',
        errorWhileLoading: 'Problema no servidor ao tentar carregar os sinais vitais, por favor tente novamente.',
        errorWhileValidating: 'Problema no servidor, ao tentar validar. Por favor tente novamente.',
        exitWithoutSave: 'Deseja sair sem salvar?',
        exitWithoutSavingUser: 'Deseja sair sem criar o usuário?',
        exitWithoutSavingEditUser: 'Deseja sair sem salvar as alterações?',
        exitWithoutSavePreset: 'As modificações não foram salvas Deseja sair sem salvar?',
        includeAHospital: 'Inclua ao menos um hospital da lista',
        invalidPhoneNumber: 'Telefone inválido',
        noChangesToSave: 'Ops! não há nenhuma alteração para ser salva',
        noVpn: 'Sem VPN',
        noGateway: 'Sem Gateway',
        NotAuthorizedException: 'Verifique o seu ID Profissional e senha.',
        patientDisassociatedSuccessfully: 'O paciente foi desassociado com sucesso!',
        patientSuccessfullySaved: 'O paciente foi salvo com sucesso!',
        pleaseSelectAtLeastOneItem: 'Por favor, selecione pelo menos um item da lista.',
        pleaseSelectPermissionLevel: 'Por favor, selecione um nível de permissão',
        pleaseSelectSomeDataToSend: 'Por favor, selecione algum dado para envio.',
        pleaseTypeAName: 'Por favor, digite um nome',
        pleaseTypeAnEmail: 'Por favor, digite um e-mail',
        pleaseTypeAnValidEmail: 'Por favor, digite um e-mail válido',
        pleaseTypeAValidName: 'Por favor, digite um nome válido',
        pleaseTypeERPCode: 'Por favor, digite o Código do PEP',
        pleaseTypeProfessionalId: 'Por favor, digite o ID Profissional',
        pleaseTypeSurname: 'Por favor, digite um sobrenome',
        professionalIdNotExist: 'A ID de Profissional informada não está cadastrada no sistema',
        redirectingToLogin: 'Redirecionando para o login...',
        requiredField: 'Campo obrigatório',
        serverErrorTryAgain: 'Sistema indisponível, tente novamente dentro de alguns instantes.',
        successfullyCreatedPassword: 'Senha cadastrada com sucesso.',
        successfullyCreatedUser: 'Usuário criado com sucesso, enviamos sua senha por e-mail, por favor verificar',
        successfullyCreatedUsers: 'Usuários cadastrados com sucesso',
        successfullyDeletedUser: 'Usuário excluído com sucesso',
        successfullyUpdatedUser: 'Usuário alterado com sucesso',
        successfullyUpdatedUsers: 'Usuários atualizados com sucesso',
        successfullyValidated: 'Dados validados com sucesso',
        theFollowingErrorsHasBeenFound: 'Os seguintes erros foram detectados:',
        updatedParams: 'Parâmetros atualizados com sucesso',
        usernameTaken: 'ID Profissional já existe, por favor informar outro',
        weSentAnEmailToRecoverYourPassword: 'Enviamos o código de acesso para o e-mail, por favor verificar.',
        weSentAnEmailToRecoverYourPasswordTo: 'Enviamos o código de acesso para o e-mail {{email}}, por favor verificar.',
        whenTraingToUpdateSessionTime: 'Ao tentar atualizar tempo de sessão.',
        forbidden: 'Perfil não autorizado, entre em contato com seu gestor.',
        badUserInput: 'Função indisponível no momento, entrar em contato com o suporte da Carenet.',
        internalServerError: 'Função indisponível no momento, tente novamente mais tarde.',
        emailAlreadyUsed: 'O e-mail "{{email}}" já foi cadastrado.',
      },
      selector: {
        selectHospital: 'Selecione Hospital',
        selectIcu: 'Selecione uma UTI',
        selectPreset: 'Preset selecionado:',
      },
      pickList: {
        leftListTitle: 'Hospitais disponíveis',
        rightListTitle: 'Hospitais selecionados',
        addAll: 'Adicionar todos >>',
        addOne: 'Adicionar hospital >',
        removeAll: '<< Retirar todos',
        removeOne: '< Retirar hospital',
      },
      floatingMenu: {
        createMultiple: 'Criar em lote',
        createUser: 'Criar usuário',
        editMultiple: 'Editar em lote',
        excludeSelection: 'Excluir seleção',
        export: 'Exportar',
        createAlarm: 'Criar preset de alarme',
        createHospital: 'Criar hospital',
        networks: 'Criar rede',
      },
      usersTable: {
        actions: 'Ações',
        fullName: 'Nome do usuário',
        userName: 'ID Profissional',
        userPosition: 'Cargo',
        role: 'Permissão',
        pepId: 'Cód do PEP',
        loadMore: 'Ver mais',
      },
      useRoles: {
        S_Admin: 'S_Admin',
        G_Admin: 'G_Admin',
        H_Admin: 'H_Admin',
        User: 'Usuário',
        User_TV: 'Usuário TV',
        User_Deploy: 'Usuário implantação',
        Orchestra_Support: 'Suporte Orchestra',
      },
      templateFile: {
        professionalId: 'ID Profissional',
        pepId: 'Cód. Do PEP',
        fullName: 'Nome e sobrenome',
        email: 'E-mail inválido',
        phone: 'Telefone inválido',
        role: 'Nível de permissão',
        userPosition: 'Cargo',
        hospital: 'Hospital vinculado',
        status: 'Status',
      },
      delpoymentTabs: {
        createHospital: 'Criar Hospital',
        configVPN: 'Configurar VPN',
        gatewayClient: 'IP gateway do cliente',
      },
      tabAir: {
        equipmentIP: 'Equipamento | IP Porta',
        unitBed: 'Unidade de internação | Leito',
        converter: 'Conversor',
        maker: 'Fabricante',
        model: 'Modelo',
        protocol: 'Protocolo',
        ipDoor: 'IP Porta',
        intReq: 'Int. Req.',
        intervalRequest: 'Intervalo requisição',
        addEquipments: 'Adicionar equipamentos',
        linkEquipmentsBed: 'Vincular equipamentos à Unidade de internação | Leitos',
        equipment: 'Equipamento',
      },
      tabMonitor: {
        unitExhibition: 'Uni. de Internação | Exibição',
        unitTechnical: 'Uni. de Internação | Técnico',
        equipment: 'Equipamento',
        bedExhibition: 'Leito | Exibição',
        bedTechnical: 'Leito | Técnico',
        hardware: 'Hardware',
        name: 'Nome',
        ipDoor: 'IP Porta',
        maker: 'Fabricante',
        familyModel: 'Família/Modelo',
        firmware: 'Firmware',
        intReq: 'Int. Req.',
        intervalRequest: 'Intervalo requisição',
        infoOrchestra: 'Informações Orchestra',
        dnsIP: 'DNS ou IP',
        door: 'Porta',
        addEquipments: 'Adicionar equipamentos',
        hardwareType: 'Tipo de hardware',
        equipmentName: 'Nome do equipamento',
        serialNumber: 'Número de série',
        firmwareVersion: 'Versão firmware',
        linkEquipmentBed: 'Vincular equipamentos à Unidade de internação/Leitos',
        unitNameExhibition: 'Unidade de internação | Nome de exibição',
        unitNameTechnical: 'Unidade de internação | Nome técnico',
        bedNameExhibition: 'Leito | Nome de exibição',
        bedNameTechnical: 'Leito | Nome técnico',
        plusICU: '+UTI',
        configWarnings: 'Configurar alertas para os leitos',
        bedFree: 'Leito livre indisponível por',
        bedOccupied: 'Leito ocupado indisponível por',
        bedStandby: 'Leito em espera indisponível por',
        minutes: 'Min',
      },
      tabConfigVPN: {
        gatewayClient: 'IP gateway do cliente*',
        prefixIP: 'IP estático*',
        algorithmCriptografy: 'Algoritmo de criptografia fase {{phase}}',
        algorithmIntegrity: 'Algoritmo de integridade fase {{phase}}',
        numberOfGroup: 'Números do grupo da fase {{phase}} DH',
        attention: 'Atenção',
        uploadStarted: 'A operação foi iniciada, aguarde {{time}} min.',
        wipeVPN: 'Excluir VPN',
        vpnCreated: 'A VPN já foi criada',
      },
      logs: {
        logHistory: 'Histórico - Logs',
        logUser: 'Informações do Usuário',
      },
      logActions: {
        userLogin: 'Realizado o login',
        userLogout: 'Realizado o logout',
        userChangePassword: 'Realizado a troca de senha',
        userChangeIcu: 'Troca de UTI, de {{0}} para {{1}}',
        userChangeHospital: 'Troca de Hospital, de {{0}} para {{1}}',
        userExclusion: 'Exclusão de usuário {{0}}',
        userActivated: 'Ativado usuário {{0}}',
        userDeactivated: 'Desativado usuário {{0}}',
        userActivatedBy: 'Ativado pelo usuário {{0}}',
        userDeactivatedBy: 'Desativado pelo usuário {{0}}',
        userBatchCreateDownload: 'Baixou modelo para criar em lote',
        userBatchCreate: 'Subiu arquivo para criar em lote',
        userBatchEditDownload: 'Baixou modelo editar em lote',
        userBatchEdit: 'Subiu arquivo editar em lote',
        userCreate: 'Cadastrado usuário {{0}}',
        userUpdate: 'Atualização de cadastro',
        userUpdateName: 'Atualizado nome: {{0}} para {{1}}',
        userUpdateUsername: 'Atualizado ID profissional: {{0}} para {{1}}',
        userUpdatePosition: 'Atualizado Cargo: {{0}} para {{1}}',
        userUpdatePhone: 'Atualizado Telefone: {{0}} para {{1}}',
        userUpdateEmail: 'Atualizado E-mail: {{0}} para {{1}}',
        userUpdateRole: 'Atualizado Nível de permissão: {{0}} para {{1}}',
        userAddHospital: 'Adicionado vinculo de hospital: {{0}} para usuário: {{1}}',
        userRemoveHospital: 'Retirado Vinculo de hospital: {{0}} do usuário {{1}}',
        userUpdatePepCode: 'Atualizado Código PEP {{0}} para Código PEP {{1}} {{2}}',
        pepSentDataToPep: 'Enviou dados do dia {{1}} para o prontuário eletrônico na data {{0}}',
        pepUpdatedVitalSign: 'Atualizado sinal vital [{{1}}], valor {{2}} para {{3}} na data {{0}}',
        pepIncludingVitalSign: 'Incluído para o sinal vital [{{1}}], valor {{2}} na data {{3}}',
        presetCreated: 'Cadastrado preset {{0}}',
        presetDuplicated: 'Duplicado preset {{0}}',
        presetExcluded: 'Exclusão o preset {{0}}',
        presetPublished: 'Publicado preset {{0}}',
        presetUnpublished: 'Despublicado preset {{0}}',
        presetLinked: 'Vinculado preset {{0}}',
        presetUnlinked: 'Desvinculado preset {{0}}',
        presetTempCreated: 'Cadastrado preset temporário {{0}}',
        presetUpdatedTitle: 'Alterado o título do preset {{0}} para {{1}}',
        presetUpdatedSpecialty: 'Alterada a especialidade do preset {{0}} para {{1}}',
        presetUpdatedVitalSign: 'Alterado parametro de alarme de {{0}} {{1}} de {{2}} para {{3}}',
        deviceLinked: 'Equipamento {{0}} "{{1}}" associado ao leito {{2}}',
        hospitalCreated: '{{0}} cadastrado',
        vpnCreate: 'VPN criada',
        pepFrequencyUpdated: 'Atualizado a configuração da frequência do {{0}} do {{1}} {{2}} minutos para {{3}} minutos',
        custom_arterial_pressure: 'PA',
        custom_diastolic_arterial_pressure: 'PAD',
        custom_heart_rate: 'FC',
        custom_mean_arterial_pressure: 'PAM',
        custom_oxygen_saturation: 'SAT O₂',
        custom_respiratory_rate: 'FR',
        custom_systolic_arterial_pressure: 'PAS',
        custom_temperature: 'Temp',
        temperatureDisabled: 'Temperatura desativada',
        temperatureEnabled: 'Temperatura ativada',
        carbonDioxideDisabled: 'CO₂ desativado',
        carbonDioxideEnabled: 'CO₂ ativado',
      },
      deployment: {
        pageTitle: 'Implantação',
        updatedAt: 'Atualizado em',
        hospitalName: 'Nome do hospital',
        network: 'Rede',
        networks: 'Redes',
        createNetwork: 'Criar Rede',
        editNetwork: 'Editar Rede',
        networkName: 'Nome da rede',
        city: 'Cidade',
        state: 'UF',
        actions: 'Ações',
        unit: 'Unidade',
        modalExcludeBed: {
          title: 'Atenção',
          text: 'Deseja excluir "{{bedName}}"?',
          text2: 'A UTI possui leito ativo, verifique!',
        },
        modalExcludeEquip: {
          title: 'Atenção',
          text: 'Deseja excluir "{{equipName}}"?',
        },
        repeatedDevice: 'Já existe um dispositivo com este nome!',
        repeatedHost: 'Este host já existe!',
        repeatedSerial: 'Este serial já existe!',
        repeatedBed: '"{{bed}}" em uso, verifique!',
      },
      labelPage: {
        productName: 'Nome comercial do Produto - Código do produto (Versão software)',
        descriptionProduct: 'Descrição do produto registrado na ANVISA',
        productDesc: 'Sistema de interoperabilidade e visualização de informações de terapia intensiva hospitalar',
        vendorName: 'Nome do Fabricante e endereço:',
        storageIndication: 'Indicação de Armazenamento, Conservação e/ou manipulação do produto, advertência e/ou precauções: vide instruções de uso do produto',
        version: 'Versão:',
        personTechnical: 'Nome do responsável técnico:',
        shelfLife: 'Prazo de validade: Indeterminado',
        composition: 'Composição: Não aplicável',
        sterile: 'Estéril: Não aplicável',
        registerClass: 'Nº registro do Conselho de Classe:',
        quantity: 'Quantidade da embalagem: Não aplicável',
        sterileMethod: 'Método de esterilização: Não aplicável',
        registerNumberProduct: 'Número de registro do produto médico ANVISA:',
        storageCondition: 'Condições de Armazenamento: Não aplicável',
        sac: 'SAC: ',
        whatsApp: 'WhatsApp: ',
      },
      logCheck: {
        enable: 'Ativar',
        disable: 'Desativar',
      },
      section: {
        seeLess: 'Ver menos',
        seeMore: 'Ver mais',
      },
      secondsUnit: 's',
      accessDenied: 'Acesso Negado!',
      action_plural: 'ações',
      action: 'Ação',
      addUser: 'Cadastro de usuário',
      all: 'Todos',
      attention: 'Atenção',
      bed_plural: 'Leitos',
      bed: 'Leito',
      changeICU: 'Troca de UTI',
      code: 'Código',
      codErp: 'Cód. Do ERP',
      complacency: 'Complacência',
      comingSoon: 'Em breve paciente watcher',
      config: 'cfg',
      configurationFile: 'Configuração',
      createPassword: 'Criar senha',
      date: ' data',
      justDate: 'Data',
      dateTime: 'Data e Hora',
      delete: 'Excluir',
      disassociatingPatient: 'Desassociar paciente',
      downloadFile: 'Baixar template',
      edit: 'Editar',
      email: 'E-mail',
      empty: 'vazio',
      error: 'erro',
      expiredSession: 'SESSÃO EXPIRADA',
      fullName: 'Nome e sobrenome',
      generateFile: 'Exportar VPN',
      hospital: 'Hospital',
      hour: 'Hora',
      incidence: 'incidência',
      indicators: 'indicadores',
      indicatorsConfiguration: 'Configurações de indicadores',
      line: 'linha',
      linkHospitals: 'Vincular hospitais',
      linkedHospitals: 'Hospitais vinculados',
      loading: 'carregando...',
      loadingSSVV: 'carregando sinais vitais...',
      log: 'Log',
      logOut: 'Sair do sistema',
      label: 'Rótulo',
      medication: 'Medicamento',
      measureUnit: 'Unidade de medida',
      modality: 'modalidade',
      mozartDownloadHere: 'Baixe o Mozart aqui',
      newPassword: 'Nova senha',
      operationsMode: 'Modo de operação',
      downloadMozardHere: 'Baixar Mozard aqui',
      orchestrationSystem: 'Sistema de Orquestração',
      password: 'Senha',
      passwordConfirmation: 'Confirmação de senha',
      pepId: 'Código PEP',
      permission: 'Permissão',
      permissionLevel: 'Nível de permissão',
      phone: 'Telefone',
      professionalData: 'Dados profissionais',
      professionalFullName: 'Nome e sobrenome do profissional',
      professionalId: 'ID Profissional',
      professionalIdSugestion: 'Sugestão: nome.sobrenome',
      professionalPosition: 'Cargo do profissional',
      resistence: 'resistencia',
      role: 'função',
      roleOpt: 'Cargo',
      qrCodeMozart: 'Escaneie o código para fazer o download do aplicativo Mozart',
      search: 'Buscar',
      searchForPreset: 'Busca por preset',
      sector: 'Ala',
      seeProfile: 'Ver perfil',
      select: 'Selecionar',
      selectAll: 'Selecionar todos',
      sendFile: 'Enviar arquivo',
      sendValidatedData: 'Enviar dados validados',
      settings: 'Configurações',
      standard: 'Padrao',
      success: 'Sucesso',
      time: 'tempo',
      uploadFile: 'Importar VPN',
      user_plural: 'usuários',
      user: 'usuário',
      userLogs: 'Logs de usuários',
      value: 'Valor',
      version: 'Versão',
      welcomeToOrchestra: '{{name}}, seja bem vindo ao Orchestra.',
      noData: 'Sem dados',
      dataSaved: 'Dados salvos',
      wentWrong: 'Ocorreu um erro, tente novamente',
      now: 'Agora',
      wait: 'Aguarde...',
      bedModalAnonymous: 'O paciente ainda não foi admitido no leito. Identifique-o para liberar o acesso.',
      alarmEquipment: {
        monitor: {
          hrTooHigh: {
            trans: 'TAQUICARDIA',
            keyword: 'TAQUICARDIA',
          },
          hrTooLow: {
            trans: 'BRADICARDIA',
            keyword: 'BRADICARDIA',
          },
          st1TooHigh: {
            trans: 'ST1 MUITO ALTA',
            keyword: 'ST1',
          },
          st1TooLow: {
            trans: 'ST1 MUITO BAIXA',
            keyword: 'ST1',
          },
          st2TooHigh: {
            trans: 'ST2 MUITO ALTA',
            keyword: 'ST2',
          },
          st2TooLow: {
            trans: 'ST2 MUITO BAIXA',
            keyword: 'ST2',
          },
          stITooHigh: {
            trans: 'ST-I MUITO ALTA',
            keyword: 'ST-I',
          },
          stITooLow: {
            trans: 'ST-I MUITO BAIXA',
            keyword: 'ST-I',
          },
          stIiTooHigh: {
            trans: 'ST-II MUITO ALTA',
            keyword: 'ST-II',
          },
          stIiTooLow: {
            trans: 'ST-II MUITO BAIXA',
            keyword: 'ST-II',
          },
          stIiiTooHigh: {
            trans: 'ST-III MUITO ALTA',
            keyword: 'ST-III',
          },
          stIiiTooLow: {
            trans: 'ST-III MUITO BAIXA',
            keyword: 'ST-III',
          },
          stAvrTooHigh: {
            trans: 'ST-aVR MUITO ALTA',
            keyword: 'ST-aVR',
          },
          stAvrTooLow: {
            trans: 'ST-aVR MUITO BAIXA',
            keyword: 'ST-aVR',
          },
          stAvlTooHigh: {
            trans: 'ST-aVL MUITO ALTA',
            keyword: 'ST-aVL',
          },
          nibpDiaTooHigh: {
            trans: 'NIBP-Dia MUITO ALTA',
            keyword: 'NIBP-DIAST',
          },
          nibpDiaTooLow: {
            trans: 'NIBP-Dia MUITO BAIXA',
            keyword: 'NIBP-DIAST',
          },
          rrTooHigh: {
            trans: 'TAQUIPNEIA',
            keyword: 'TAQUIPNEIA',
          },
          rrTooLow: {
            trans: 'BRADIPNEIA',
            keyword: 'BRADIPNEIA',
          },
          ibp1SysTooHigh: {
            trans: 'IBP1-Sist MUITO ALTA',
            keyword: 'IBP1-SIST',
          },
          ibp1SysTooLow: {
            trans: 'IBP1-Sist MUITO BAIXA',
            keyword: 'IBP1-SIST',
          },
          ibp1MeanTooHigh: {
            trans: 'IBP1-Média MUITO ALTA',
            keyword: 'IBP1-MÉDIA',
          },
          ibp1MeanTooLow: {
            trans: 'IBP1-Média MUITO BAIXA',
            keyword: 'IBP1-MÉDIA',
          },
          ibp1DiaTooHigh: {
            trans: 'IBP1-Diast MUITO ALTA',
            keyword: 'IBP1-DIAST',
          },
          ibp1DiaTooLow: {
            trans: 'IBP1-Diast MUITO BAIXA',
            keyword: 'IBP1-DIAST',
          },
          ibp2SysTooHigh: {
            trans: 'IBP2-Sist MUITO ALTA',
            keyword: 'IBP2-SIST',
          },
          ibp2SysTooLow: {
            trans: 'IBP2-Sist MUITO BAIXA',
            keyword: 'IBP2-SIST',
          },
          ibp2MeanTooHigh: {
            trans: 'IBP2-Média MUITO ALTA',
            keyword: 'IBP2-MÉDIA',
          },
          ibp2MeanTooLow: {
            trans: 'IBP2-Média MUITO BAIXA',
            keyword: 'IBP2-MÉDIA',
          },
          ibp2DiaTooHigh: {
            trans: 'IBP2-Diast MUITO ALTA',
            keyword: 'IBP2-DIAST',
          },
          ibp2DiaTooLow: {
            trans: 'IBP2-Diast MUITO BAIXA',
            keyword: 'IBP2-DIAST',
          },
          ibp3SysTooHigh: {
            trans: 'IBP3-Sist MUITO ALTA',
            keyword: 'IBP3-SIST',
          },
          ibp3SysTooLow: {
            trans: 'IBP3-Sist MUITO BAIXA',
            keyword: 'IBP3-SIST',
          },
          ibp3MeanTooHigh: {
            trans: 'IBP3-Média MUITO ALTA',
            keyword: 'IBP3-MÉDIA',
          },
          ibp3MeanTooLow: {
            trans: 'IBP3-Média MUITO BAIXA',
            keyword: 'IBP3-MÉDIA',
          },
          ibp3DiaTooHigh: {
            trans: 'IBP3-Diast MUITO ALTA',
            keyword: 'IBP3-DIAST',
          },
          ibp3DiaTooLow: {
            trans: 'IBP3-Diast MUITO BAIXA',
            keyword: 'IBP3-DIAST',
          },
          ibp4SysTooHigh: {
            trans: 'IBP4-Sist MUITO ALTA',
            keyword: 'IBP4-SIST',
          },
          ibp4SysTooLow: {
            trans: 'IBP4-Sist MUITO BAIXA',
            keyword: 'IBP4-SIST',
          },
          ibp4MeanTooLow: {
            trans: 'IBP4-Média MUITO BAIXA',
            keyword: 'IBP4-MÉDIA',
          },
          ibp4DiaTooLow: {
            trans: 'IBP4-Diast MUITO BAIXA',
            keyword: 'IBP4-DIAST',
          },
          fico2TooLow: {
            trans: 'FiCO2 MUITO BAIXA',
            keyword: 'FiCO2',
          },
          etco2TooLow: {
            trans: 'EtCO2 MUITO BAIXO',
            keyword: 'EtCO2',
          },
          awrrTooLow: {
            trans: 'awRR MUITO BAIXO',
            keyword: 'AwRR',
          },
          t1TooLow: {
            trans: 'T1 MUITO BAIXO',
            keyword: 'T1',
          },
          t2TooLow: {
            trans: 'T2 MUITO BAIXO',
            keyword: 'T2',
          },
          tdTooLow: {
            trans: 'Td MUITO BAIXO',
            keyword: 'Td',
          },
          tbTooLow: {
            trans: 'TB MUITO BAIXO',
            keyword: 'TB',
          },
          eto2TooLow: {
            trans: 'EtO2 MUITO BAIXO',
            keyword: 'EtO2',
          },
          fio2TooLow: {
            trans: 'FiO2 MUITO BAIXA',
            keyword: 'FiO2',
          },
          etn2oTooLow: {
            trans: 'EtN2O MUITO BAIXO',
            keyword: 'EtN2O',
          },
          fin2oTooLow: {
            trans: 'FiN2O MUITO BAIXA',
            keyword: 'FiN2O',
          },
          etaaTooLow: {
            trans: 'EtAA MUITO BAIXO',
            keyword: 'EtAA',
          },
          fiaaTooLow: {
            trans: 'FiAA MUITO BAIXA',
            keyword: 'FiAA',
          },
          ethalTooLow: {
            trans: 'EtHAL MUITO BAIXO',
            keyword: 'EtHAL',
          },
          fihalTooLow: {
            trans: 'FiHAL MUITO BAIXA',
            keyword: 'FiHAL',
          },
          etenfTooLow: {
            trans: 'EtENF MUITO BAIXO',
            keyword: 'EtENF',
          },
          fienfTooLow: {
            trans: 'FiENF MUITO BAIXA',
            keyword: 'FiENF',
          },
          etisoTooLow: {
            trans: 'EtISO MUITO BAIXO',
            keyword: 'EtISO',
          },
          fiisoTooLow: {
            trans: 'FiISO MUITO BAIXA',
            keyword: 'FiISO',
          },
          etsevTooLow: {
            trans: 'EtSEV MUITO BAIXO',
            keyword: 'EtSEV',
          },
          fisevTooLow: {
            trans: 'FiSEV MUITO BAIXA',
            keyword: 'FiSEV',
          },
          etdesTooLow: {
            trans: 'EtDES MUITO BAIXO',
            keyword: 'EtDES',
          },
          fidesTooLow: {
            trans: 'FiDES MUITO BAIXA',
            keyword: 'FiDES',
          },
          agawrrTooLow: {
            trans: 'AgAwRR MUITO BAIXA',
            keyword: 'AgAwRR',
          },
          gasApnea: {
            trans: 'APNEIA',
            keyword: 'Apneia',
          },
          ecgLost: {
            trans: 'DESCONEXÃO ECG',
            keyword: 'ECG',
          },
          noPulse: {
            trans: 'SEM PULSO',
            keyword: 'SEM PULSO',
          },
          apnea: {
            trans: 'APNEIA',
            keyword: 'APNEIA',
          },
          asystole: {
            trans: 'ASSISTOLIA',
            keyword: 'ASSISTOLIA',
          },
          fio2TooHigh: {
            trans: 'FiO2 MUITO ALTA',
            keyword: 'FiO2',
          },
          peepTooHigh: {
            trans: 'PEEP MUITO ALTO',
            keyword: 'PEEP',
          },
          peepTooLow: {
            trans: 'PEEP MUITO BAIXO',
            keyword: 'PEEP',
          },
          pipTooHigh: {
            trans: 'PIP MUITO ALTO',
            keyword: 'PIP',
          },
          pipTooLow: {
            trans: 'PIP MUITO BAIXO',
            keyword: 'PIP',
          },
          bisTooHigh: {
            trans: 'BIS MUITO ALTO',
            keyword: 'BIS',
          },
          bisTooLow: {
            trans: 'BIS MUITO BAIXO',
            keyword: 'BIS',
          },
          paSysTooHigh: {
            trans: 'PAS MUITO ALTA',
            keyword: 'PAS',
          },
          paSysTooLow: {
            trans: 'PAS MUITO BAIXA',
            keyword: 'PAS',
          },
          paMeanTooHigh: {
            trans: 'PAM MUITO ALTA',
            keyword: 'PAM',
          },
          paMeanTooLow: {
            trans: 'PAM MUITO BAIXA',
            keyword: 'PAM',
          },
          paDiaTooHigh: {
            trans: 'PAD MUITO ALTA',
            keyword: 'PAD',
          },
          paDiaTooLow: {
            trans: 'PAD MUITO BAIXA',
            keyword: 'PAD',
          },
          pause: {
            trans: 'PAUSA',
            keyword: 'PAUSA',
          },
          alarmPause: {
            trans: 'PAUSA DE ALARME',
            keyword: 'PAUSA',
          },
          deltaSpo2TooHigh: {
            trans: 'DELTA SPO2 MUITO ALTA',
            keyword: 'SPO2b',
          },
          tempTooHigh: {
            trans: 'TEMP MUITO ALTA',
            keyword: 'TEMPERATURA',
          },
          tempTooLow: {
            trans: 'TEMP MUITO BAIXA',
            keyword: 'TEMPERATURA',
          },
          ecgInitializationError: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgInitializationError1: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgInitializationError2: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgInitializationError3: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgInitializationError4: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgInitializationError5: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgInitializationError6: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgInitializationError7: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgInitializationError8: {
            trans: 'ECG - ERRO DE INICIALIZAÇÃO',
            keyword: 'ECG',
          },
          ecgCh1SelftestError: {
            trans: 'ECG CH1 - ERRO NO AUTOTESTE',
            keyword: 'ECG',
          },
          ecgCh2SelftestError: {
            trans: 'ECG CH2 - ERRO NO AUTOTESTE',
            keyword: 'ECG',
          },
          ecgCommunicationStopped: {
            trans: 'ECG - COMUNICAÇÃO INTERROMPIDA',
            keyword: 'ECG',
          },
          ecgCommunicationError: {
            trans: 'ECG - ERRO DE COMUNICAÇÃO',
            keyword: 'ECG',
          },
          hrOutOfRange: {
            trans: 'FC - NÃO IDENTIFICADA',
            keyword: 'FC',
          },
          stOutOfRange: {
            trans: 'ST - NÃO IDENTIFICADA',
            keyword: 'ST',
          },
          st1OutOfRange: {
            trans: 'ST1 - NÃO IDENTIFICADA',
            keyword: 'ST1',
          },
          st2OutOfRange: {
            trans: 'ST2 - NÃO IDENTIFICADA',
            keyword: 'ST2',
          },
          st3OutOfRange: {
            trans: 'ST3 - NÃO IDENTIFICADA',
            keyword: 'ST3',
          },
          st4OutOfRange: {
            trans: 'ST4 - NÃO IDENTIFICADA',
            keyword: 'ST4',
          },
          st5OutOfRange: {
            trans: 'ST5 - NÃO IDENTIFICADA',
            keyword: 'ST5',
          },
          st6OutOfRange: {
            trans: 'ST6 - NÃO IDENTIFICADA',
            keyword: 'ST6',
          },
          st7OutOfRange: {
            trans: 'ST7 - NÃO IDENTIFICADA',
            keyword: 'ST7',
          },
          rrOutOfRange: {
            trans: 'FR - NÃO IDENTIFICADA',
            keyword: 'FR',
          },
          spo2SensorOff: {
            trans: 'SpO2 - SENSOR DESLIGADO',
            keyword: 'SpO2',
          },
          spo2SearchingForPulse: {
            trans: 'SpO2 - PROCURANDO PULSO',
            keyword: 'SpO2',
          },
          spo2InitializationError: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2InitializationError1: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2InitializationError2: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2InitializationError3: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2InitializationError4: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2InitializationError5: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2InitializationError6: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2InitializationError7: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2InitializationError8: {
            trans: 'SpO2 - ERRO DE INICIALIZAÇÃO',
            keyword: 'SpO2',
          },
          spo2CommunicationStopped: {
            trans: 'SpO2 - COMUNICAÇÃO INTERROMPIDA',
            keyword: 'SpO2',
          },
          spo2CommunicationError: {
            trans: 'SpO2 - ERRO DE COMUNICAÇÃO',
            keyword: 'SpO2',
          },
          spo2OutOfRange: {
            trans: 'SpO2 - NÃO IDENTIFICADA',
            keyword: 'SpO2',
          },
          spo2WeakPulse: {
            trans: 'SpO2 - PULSO FRACO',
            keyword: 'SpO2',
          },
          spo2WeakSignal: {
            trans: 'SpO2 - SINAL FRACO',
            keyword: 'SpO2',
          },
          spo2CheckSensor: {
            trans: 'SpO2 - VERIFICAR SENSOR',
            keyword: 'SpO2',
          },
          spo2SensorInMotion: {
            trans: 'SpO2 - SENSOR EM MOVIMENTO',
            keyword: 'SpO2',
          },
          spo2Interference: {
            trans: 'SpO2 - INTERFERÊNCIA',
            keyword: 'SpO2',
          },
          spo2LowPerfusion: {
            trans: 'SpO2 - BAIXA PERFUSÃO',
            keyword: 'SpO2',
          },
          spo2TooMuchLight: {
            trans: 'SpO2 - EXCESSO DE LUMINOSIDADE',
            keyword: 'SpO2',
          },
          spo2UnrecognizedSensor: {
            trans: 'SpO2 - SENSOR NÃO RECONHECIDO',
            keyword: 'SpO2',
          },
          spo2SensorFault: {
            trans: 'SpO2 - FALHA NO SENSOR',
            keyword: 'SpO2',
          },
          spo2NoSensor: {
            trans: 'SpO2 - AUSÊNCIA DE SENSOR',
            keyword: 'SpO2',
          },
          spo2LowSignal: {
            trans: 'SpO2 - SINAL BAIXO',
            keyword: 'SpO2',
          },
          spo2IncompatibleSensor: {
            trans: 'SpO2 - SENSOR INCOMPATÍVEL',
            keyword: 'SpO2',
          },
          tempInitializationError: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError1: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError2: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError3: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError4: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError5: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError6: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError7: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError8: {
            trans: 'TEMP - ERRO DE INICIALIZAÇÃO',
            keyword: 'TEMPERATURA',
          },
          t1OutOfRange: {
            trans: 'T1 - NÃO IDENTIFICADA',
            keyword: 'T1',
          },
          t2OutOfRange: {
            trans: 'T2 - NÃO IDENTIFICADA',
            keyword: 'T2',
          },
          tdOutOfRange: {
            trans: 'TD - NÃO IDENTIFICADA',
            keyword: 'TD',
          },
          tempCommunicationError: {
            trans: 'TEMP - ERRO DE COMUNICAÇÃO',
            keyword: 'TEMPERATURA',
          },
          tempCommunicationStopped: {
            trans: 'TEMP - COMUNICAÇÃO INTERROMPIDA',
            keyword: 'TEMPERATURA',
          },
          tempSelftestError: {
            trans: 'TEMP - FALHA NO AUTOTESTE',
            keyword: 'TEMPERATURA',
          },
          tempCalibrationError: {
            trans: 'TEMP - ERRO DE CALIBRAÇÃO',
            keyword: 'TEMPERATURA',
          },
        },
        infusion: {
          endOfLimitTime: {
            trans: 'FIM DO TEMPO LIMITE DE INFUSÃO',
            keyword: 'FIM INFUSÃO',
          },
          endOfVolumeTime: {
            trans: 'FIM VOLUME/TEMPO DE INFUSÃO',
            keyword: 'FIM INFUSÃO',
          },
          battery: {
            trans: 'BATERIA',
            keyword: 'BATERIA',
          },
          occlusion: {
            trans: 'OCLUSÃO',
            keyword: 'OCLUSÃO',
          },
          syringeClamp: {
            trans: 'FALHA NO CLAMP DA SERINGA',
            keyword: 'CLAMP',
          },
          flange: {
            trans: 'FALHA NO ENCAIXE DA FLANGE DA SERINGA',
            keyword: 'FLANGE',
          },
          plungerHead: {
            trans: 'FALHA NO ENCAIXE DO ÊMBOLO DA SERINGA',
            keyword: 'ÊMBOLO',
          },
          disengagement: {
            trans: 'DESCONEXÃO DO CIRCUITO',
            keyword: 'CIRCUITO',
          },
          forceSensor: {
            trans: 'SENSOR DE FORÇA',
            keyword: 'SENSOR FORÇA',
          },
          pcaProtectiveCoverMissing: {
            trans: 'PCA - AUSÊNCIA DA TAMPA DE PROTEÇÃO',
            keyword: 'PCA',
          },
          pcaProtectiveCoverNotLocked: {
            trans: 'PCA - TAMPA DE PROTEÇÃO ABERTA',
            keyword: 'PCA',
          },
          pcaMaxVolumeInfused: {
            trans: 'PCA - VOLUME MÁXIMO INFUNDIDO',
            keyword: 'PCA',
          },
          pcaMaxDoseInfused: {
            trans: 'PCA - DOSE MÁXIMA INFUNDIDA',
            keyword: 'PCA',
          },
          clampMissing: {
            trans: 'AUSÊNCIA DE CLAMP',
            keyword: 'CLAMP',
          },
          endOfInfusion: {
            trans: 'FIM DA INFUSÃO',
            keyword: 'FIM INFUSÃO',
          },
          endOfSecondaryInfusion: {
            trans: 'FIM DA INFUSÃO SECUNDÁRIA',
            keyword: 'FIM INFUSÃO',
          },
          downstreamOcclusion: {
            trans: 'OBSTRUÇÃO DE FLUXO FINAL',
            keyword: 'OBSTRUÇÃO',
          },
          doorOpened: {
            trans: 'PORTA ABERTA',
            keyword: 'PORTA ABERTA',
          },
          setNotDetectedByPressureDownstreamSensor: {
            trans: 'SISTEMA NÃO DETECTADO PELO SENSOR DE PRESSÃO FINAL',
            keyword: 'SISTEMA',
          },
          setNotDetectedByUpstreamPressureSensor: {
            trans: 'SISTEMA NÃO DETECTADO PELO SENSOR DE PRESSÃO INICIAL',
            keyword: 'SISTEMA',
          },
          setNotInstalledCorrectly: {
            trans: 'SISTEMA NÃO INSTALADO CORRETAMENTE',
            keyword: 'SISTEMA',
          },
          upstreamOcclusion: {
            trans: 'OBSTRUÇÃO DE FLUXO INICIAL',
            keyword: 'OBSTRUÇÃO',
          },
          emptyBagUpstreamPressure: {
            trans: 'BOLSA VAZIA IDENTIFICADA DURANTE PRESSÃO INICIAL',
            keyword: 'BOLSA VAZIA',
          },
          uncontrolledFlow: {
            trans: 'FLUXO DESCONTROLADO',
            keyword: 'FLUXO',
          },
          noDrop: {
            trans: 'SEM GOTEJAMENTO',
            keyword: 'GOTEJAMENTO',
          },
          underflowDetectedByDropSensor: {
            trans: 'FLUXO ABAIXO DO ESPERADO',
            keyword: 'FLUXO',
          },
          overflowDetectedByDropSensor: {
            trans: 'FLUXO ACIMA DO ESPERADO',
            keyword: 'FLUXO',
          },
          absenceOfDropSensor: {
            trans: 'SENSOR DE GOTEJAMENTO AUSENTE',
            keyword: 'GOTEJAMENTO',
          },
          dropSensorHasBeenConnectedDuringInfusion: {
            trans: 'SENSOR DE GOTEJAMENTO CONECTADO DURANTE A INFUSÃO',
            keyword: 'GOTEJAMENTO',
          },
          airDetection: {
            trans: 'AR NO CIRCUITO',
            keyword: 'PRESENÇA AR',
          },
          airVolumeExceededLimit: {
            trans: 'EXCESSO DE AR NO CIRCUITO',
            keyword: 'PRESENÇA AR',
          },
          airSensorDefective: {
            trans: 'FALHA NO SENSOR DE AR',
            keyword: 'SENSOR AR',
          },
          endOfLimitVolume: {
            trans: 'FIM DO VOLUME LIMITE DE INFUSÃO',
            keyword: 'FIM INFUSÃO',
          },
          pcaPatientHandsetMissing: {
            trans: 'PCA - MÓDULO DE CONTROLE PELO PACIENTE NÃO LOCALIZADO',
            keyword: 'PCA',
          },
          pcaPresenceOfProtectiveCover: {
            trans: 'PCA - PRESENÇA DE TAMPA DE SEGURANÇA',
            keyword: 'PCA',
          },
          pcaMaxNumberOfPatientBoluses: {
            trans: 'PCA - QTDE MÁXIMA DE BOLUS ACIONADOS PELO PACIENTE',
            keyword: 'PCA',
          },
          endOfVolumeLimitKvo: {
            trans: 'KVO - FIM DE VOLUME LIMITE',
            keyword: 'KVO',
          },
          endOfVolumeTimeKvo: {
            trans: 'KVO - FIM DE VOLUME / TEMPO',
            keyword: 'KVO',
          },
          emptySyringeMode: {
            trans: 'MODO SERINGA VAZIA',
            keyword: 'SERINGA',
          },
          mainUnplugged: {
            trans: 'SISTEMA DESCONECTADO',
            keyword: 'SISTEMA',
          },
          pressureRise: {
            trans: 'PRESSÃO AUMENTADA',
            keyword: 'PRESSÃO',
          },
          pressureDown: {
            trans: 'PRESSÃO DIMINUÍDA',
            keyword: 'PRESSÃO',
          },
          keyboardLocked: {
            trans: 'TECLADO BLOQUEADO',
            keyword: 'TECLADO',
          },
          keyboardLockedAvailableInIdleMode: {
            trans: 'TECLADO BLOQUEADO DISPONÍVEL NO MODO INATIVO',
            keyword: 'TECLADO',
          },
          flowRateLow: {
            trans: 'FLUXO BAIXO',
            keyword: 'FLUXO BAIXO',
          },
          flowRateHigh: {
            trans: 'FLUXO ALTO',
            keyword: 'FLUXO ALTO',
          },
          loadingDoseLow: {
            trans: 'DOSE INICIAL BAIXA',
            keyword: 'DOSE INICIAL',
          },
          loadingDoseHigh: {
            trans: 'DOSE INICIAL ALTA',
            keyword: 'DOSE INICIAL',
          },
          infusionStartDelayedAfterEndOfPause: {
            trans: 'INFUSÃO ATRASADA APÓS PAUSA',
            keyword: 'INFUSÃO',
          },
          weakBattery: {
            trans: 'BATERIA FRACA',
            keyword: 'BATERIA',
          },
          tciConcentrationLow: {
            trans: 'TCI - CONCENTRAÇÃO BAIXA',
            keyword: 'TCI',
          },
          tciConcentrationHigh: {
            trans: 'TCI - CONCENTRAÇÃO ALTA',
            keyword: 'TCI',
          },
          nonValidationWhenNoAction: {
            trans: 'AUSÊNCIA DE VALIDAÇÃO ENQUANTO NÃO HÁ AÇÃO',
            keyword: 'SISTEMA',
          },
          nonValidationWhileWaiting: {
            trans: 'AUSÊNCIA DE VALIDAÇÃO DURANTE ESPERA',
            keyword: 'SISTEMA',
          },
          nonValidationWhenChoosingValue: {
            trans: 'AUSÊNCIA DE VALIDAÇÃO DURANTE AJUSTE DE VALORES',
            keyword: 'SISTEMA',
          },
          deviceTemperatureHigh: {
            trans: 'TEMPERATURA DO EQUIPAMENTO ELEVADA',
            keyword: 'EQUIPAMENTO',
          },
          programmedBolusDoseLow: {
            trans: 'DOSE BOLUS BAIXA',
            keyword: 'DOSE BOLUS',
          },
          programmedBolusDoseHigh: {
            trans: 'DOSE BOLUS ALTA',
            keyword: 'DOSE BOLUS',
          },
          keyboardLockedScreen: {
            trans: 'TELA DO TECLADO BLOQUEADA',
            keyword: 'TECLADO',
          },
          mainsFluctuation: {
            trans: 'FLUTUAÇÃO NO SISTEMA PRINCIPAL',
            keyword: 'SISTEMA',
          },
          pcaEndOfDoctorBolusWithMaxDoseInfused: {
            trans: 'PCA - FIM DA APLICAÇÃO DE DOSE MÁXIMA INFUNDIDA POR BOLUS',
            keyword: 'PCA',
          },
          clinicalInformation: {
            trans: 'INFORMAÇÃO CLÍNICA',
            keyword: 'SISTEMA',
          },
          occlusivityControlNotCompliant: {
            trans: 'CONTROLE DE OCLUSÃO INADEQUADO',
            keyword: 'OCLUSÃO',
          },
          endOfInfusionKvo: {
            trans: 'KVO - FIM DE INFUSÃO',
            keyword: 'KVO',
          },
          mainsSupplyOscillation: {
            trans: 'REDE ELÉTRICA INSTÁVEL',
            keyword: 'EQUIPAMENTO',
          },
          dropSensorPresent: {
            trans: 'SENSOR DE GOTEJAMENTO PRESENTE',
            keyword: 'GOTEJAMENTO',
          },
          airAlarm: {
            trans: 'AR NO CIRCUITO',
            keyword: 'PRESENÇA AR',
          },
          airAlarmAirBubbleTooLarge: {
            trans: 'BOLHAS DE AR MUITO GRANDES NO CIRCUITO',
            keyword: 'PRESENÇA AR',
          },
          airAlarmAirRateExceeded: {
            trans: 'VOLUME DE AR NO CIRCUITO EXCEDIDO',
            keyword: 'PRESENÇA AR',
          },
          airAlarmSensorTestFaulty: {
            trans: 'FALHA NO TESTE DE SENSOR DE AR',
            keyword: 'SISTEMA',
          },
          batteryAlarm: {
            trans: 'ALARME DE BATERIA',
            keyword: 'BATERIA',
          },
          batteryCoverOpen: {
            trans: 'TAMPA DA BATERIA ABERTA',
            keyword: 'BATERIA',
          },
          batteryDefective: {
            trans: 'BATERIA COM DEFEITO',
            keyword: 'BATERIA',
          },
          batteryNearFlat: {
            trans: 'BATERIA QUASE DESCARREGADA',
            keyword: 'BATERIA',
          },
          batteryNotInPump: {
            trans: 'AUSÊNCIA DE BATERIA NA BOMBA',
            keyword: 'BATERIA',
          },
          batteryVoltageTooLow: {
            trans: 'VOLTAGEM DA BATERIA MUITO BAIXA',
            keyword: 'BATERIA',
          },
          calibrationDataCorrupt: {
            trans: 'DADOS DE CALIBRAÇÃO CORROMPIDOS',
            keyword: 'EQUIPAMENTO',
          },
          clawMalfunction: {
            trans: 'MAU FUNCIONAMENTO DA GARRA',
            keyword: 'EQUIPAMENTO',
          },
          dangerOfFreeFlow: {
            trans: 'RISCO DE FLUXO LIVRE',
            keyword: 'FLUXO LIVRE',
          },
          dataLockUnauthorizedManipulation: {
            trans: 'MANIPULAÇÃO NÃO PERMITIDA',
            keyword: 'SISTEMA',
          },
          doorWasOpenedDuringTheInfusion: {
            trans: 'PORTA ABERTA DURANTE A INFUSÃO',
            keyword: 'PORTA ABERTA',
          },
          dropAlarmFreeFlow: {
            trans: 'FLUXO LIVRE',
            keyword: 'FLUXO LIVRE',
          },
          dropAlarmNoDropSensor: {
            trans: 'AUSÊNCIA DE SENSOR DE GOTEJAMENTO',
            keyword: 'EQUIPAMENTO',
          },
          dropAlarmNoDrops: {
            trans: 'SEM GOTEJAMENTO',
            keyword: 'GOTEJAMENTO',
          },
          dropAlarmTooLessDrops: {
            trans: 'GOTEJAMENTO MUITO LENTO',
            keyword: 'GOTEJAMENTO',
          },
          dropAlarmTooManyDrops: {
            trans: 'GOTEJAMENTO MUITO RÁPIDO',
            keyword: 'GOTEJAMENTO',
          },
          infusionTimeExpired: {
            trans: 'TEMPO DE INFUSÃO FINALIZADO',
            keyword: 'FIM INFUSÃO',
          },
          kvoEnd: {
            trans: 'FIM DO KVO',
            keyword: 'KVO',
          },
          kvoOperation: {
            trans: 'KVO',
            keyword: 'KVO',
          },
          occlusionTestEncountersAnError: {
            trans: 'ERRO IDENTIFICADO PELO TESTE DE OCLUSÃO',
            keyword: 'SISTEMA',
          },
          pressureSensorDefect: {
            trans: 'DEFEITO NO SENSOR DE PRESSÃO',
            keyword: 'EQUIPAMENTO',
          },
          pressureTooHigh: {
            trans: 'PRESSÃO MUITO ALTA',
            keyword: 'PRESSÃO',
          },
          pressureTooHighDriveBlocked: {
            trans: 'INFUSÃO BLOQUEADA DEVIO À ALTA PRESSÃO',
            keyword: 'PRESSÃO',
          },
          standbyTimeExpired: {
            trans: 'TEMPO DE ESPERA FINALIZADO',
            keyword: 'ESPERA',
          },
          syringeEmpty: {
            trans: 'SERINGA VAZIA',
            keyword: 'SERINGA',
          },
          syringeNotInsertedCorrect: {
            trans: 'SERINGA NÃO INSERIDA CORRETAMENTE',
            keyword: 'SERINGA',
          },
          syringeNotInsertedSecurely: {
            trans: 'SERINGA NÃO INSERIDA DE MODO SEGURO',
            keyword: 'SERINGA',
          },
          timeNearEnd: {
            trans: 'TEMPO DE INFUSÃO PRÓXIMO AO FIM',
            keyword: 'TEMPO INFUSÃO',
          },
          volumeInfused: {
            trans: 'VOLUME INFUNDIDO',
            keyword: 'INFUSÃO',
          },
          volumeToBeDeliveredNearEnd: {
            trans: 'VOLUME DE INFUSÃO PREVISTO PRÓXIMO AO FIM',
            keyword: 'INFUSÃO',
          },
          oclusO: {
            trans: 'OCLUSÃO',
            keyword: 'OCLUSÃO',
          },
          vazOLivre: {
            trans: 'VAZÃO LIVRE',
            keyword: 'FLUXO LIVRE',
          },
          bateriaCrTica: {
            trans: 'BATERIA CRÍTICA',
            keyword: 'BATERIA',
          },
          infusOConcluDa: {
            trans: 'INFUSÃO CONCLUÍDA',
            keyword: 'FIM INFUSÃO',
          },
          alarmeDeTravamento: {
            trans: 'TRAVAMENTO',
            keyword: 'SISTEMA',
          },
          alarmeDeDesengateMotor: {
            trans: 'DESENGATE DE SERINGA',
            keyword: 'SERINGA',
          },
          alarmeIdentificaODeSeringa: {
            trans: 'IDENTIFICAÇÃO DE SERINGA',
            keyword: 'SERINGA',
          },
          alarmePosiODeSeringa: {
            trans: 'POSIÇÃO DE SERINGA',
            keyword: 'SERINGA',
          },
          tampaPcaAberta: {
            trans: 'PCA - TAMPA ABERTA',
            keyword: 'PCA',
          },
          erroSensorDePosiO: {
            trans: 'ERRO NO SENSOR DE POSIÇÃO',
            keyword: 'EQUIPAMENTO',
          },
          erroSensorDaSeringa: {
            trans: 'ERRO NO SENSOR DA SERINGA',
            keyword: 'EQUIPAMENTO',
          },
          erroNoAcionadorPca: {
            trans: 'ERRO NO ACIONADOR DO PCA',
            keyword: 'EQUIPAMENTO',
          },
          portaAberta: {
            trans: 'PORTA ABERTA',
            keyword: 'SISTEMA',
          },
          erroNoMecanismo: {
            trans: 'ERRO NO MECANISMO DA BOMBA',
            keyword: 'EQUIPAMENTO',
          },
          arNaLinha: {
            trans: 'AR NA LINHA',
            keyword: 'PRESENÇA AR',
          },
          frascoVazio: {
            trans: 'FRASCO VAZIO',
            keyword: 'FRASCO VAZIO',
          },
          erroNoSensorGotas: {
            trans: 'ERRO NO SENSOR DE GOTEJAMENTO',
            keyword: 'GOTEJAMENTO',
          },
          erroCortaFluxo: {
            trans: 'ERRO NO CORTA-FLUXO',
            keyword: 'EQUIPAMENTO',
          },
          sistemaReiniciado: {
            trans: 'SISTEMA REINICIADO',
            keyword: 'SISTEMA',
          },
          oclusOSuperior: {
            trans: 'OCLUSÃO SUPERIOR',
            keyword: 'OCLUSÃO',
          },
          soluOConcluDa: {
            trans: 'INFUSÃO CONCLUÍDA',
            keyword: 'FIM INFUSÃO',
          },
        },
        air: {
          o2ConcentrationTooHigh: {
            trans: 'CONCENTRAÇÃO DE O2 MUITO ALTA',
            keyword: 'CONC. O2',
          },
          o2ConcentrationTooLow: {
            trans: 'CONCENTRAÇÃO DE O2 MUITO BAIXA',
            keyword: 'CONC. O2',
          },
          upperPressureExceeded: {
            trans: 'PRESSÃO SUPERIOR EXCEDIDA',
            keyword: 'PRESSÃO',
          },
          expMinuteVolume: {
            trans: 'VOLUME/MIN EXPIRATÓRIO',
            keyword: 'EXPIRAÇÃO',
          },
          apneaAlarmBackupVentilation: {
            trans: 'APNEIA',
            keyword: 'APNEIA',
          },
          gasSupplyAlarm: {
            trans: 'ALARME DE SUPRIMENTO DE GASES',
            keyword: 'GASES',
          },
          battery: {
            trans: 'BATERIA',
            keyword: 'BATERIA',
          },
          powerFailure: {
            trans: 'FALHA NA ALIMENTAÇÃO DE ENERGIA',
            keyword: 'ENERGIA',
          },
          mainsFailure: {
            trans: 'FALHA NO FUNCIONAMENTO DO VENTILADOR',
            keyword: 'VENTILADOR',
          },
          barometerError: {
            trans: 'ERRO NO BARÔMETRO',
            keyword: 'BARÔMETRO',
          },
          highContinuousPressure: {
            trans: 'PRESSÃO CONTÍNUA ELEVADA',
            keyword: 'PRESSÃO',
          },
          o2CellDisconnect: {
            trans: 'CÉLULA DE O2 DESCONECTADA',
            keyword: 'CÉLULA O2',
          },
          timeInWaitingPositionExceeded2Min: {
            trans: 'TEMPO NA POSIÇÃO DE ESPERA EXCEDEU 2 MIN',
            keyword: 'ESPERA ',
          },
          noPatientEffort: {
            trans: 'ESFORÇO DO DOENTE NÃO DETECTADO',
            keyword: 'ESFORÇO',
          },
          leakageOutOfRange: {
            trans: 'FUGA FORA DO USUAL',
            keyword: 'FUGA',
          },
          checkTubing: {
            trans: 'VERIFICAR A TUBAGEM',
            keyword: 'TUBAGEM',
          },
          regulationPressureLimited: {
            trans: 'REGULAÇÃO DE PRESSÃO LIMITADA',
            keyword: 'PRESSÃO',
          },
          breathFrequencyHigh: {
            trans: 'FR ALTA',
            keyword: 'FR ALTA',
          },
          breathFrequencyLow: {
            trans: 'FR BAIXA',
            keyword: 'FR BAIXA',
          },
          peepLow: {
            trans: 'PEEP BAIXA',
            keyword: 'PEEP BAIXA',
          },
          peepHigh: {
            trans: 'PEEP ALTA',
            keyword: 'PEEP ALTA',
          },
          expMinuteVolumeTooHigh: {
            trans: 'VOLUME/MIN EXPIRATÓRIO ALTO',
            keyword: 'EXPIRAÇÃO',
          },
          expMinuteVolumeTooLow: {
            trans: 'VOLUME/MIN EXPIRATÓRIO BAIXO',
            keyword: 'EXPIRAÇÃO',
          },
          noConsistentPatientEffort: {
            trans: 'AUSÊNCIA DE ESFORÇO CONSISTENTE DO PACIENTE',
            keyword: 'ESFORÇO',
          },
          highPriorityAlarm: {
            trans: 'ALARME DE ALTA PRIORIDADE',
            keyword: 'VENTILADOR',
          },
        },
        bed: {},
      },
    },
  },
};
